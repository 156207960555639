:root {
  --main-font-family: 'Open Sans';
  --btn-submit-bg-color: #737578;
  --btn-submit-text-color: #fff;
  --disclaimer-text-color: #505253;
}

.card {
  border-radius: 0;
  border: 0;
}

.card > .card-body > .row {
  margin-bottom: 1rem;
}

.row-login {
  margin: 0;
}

.row-login > * {
  padding-left: 0;
  padding-right: 0;
}

.container-size {
  padding-bottom: 2rem;
}

.login-header {
  color: #ffffff;
  background: #fff;
  border-bottom: 1px solid #c6c7c7;
  padding: 16px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  position: relative;
}

.login-header .border-start {
  border-color: #c6c7c7 !important;
}

.logo-header {
  height: 47px;
}

.logo-header-mi-tienda {
  height: 60px;
}

.login-body {
  /*margin: 0 1rem;*/
  background: #fff;
  /*border: 2px #eee solid;*/
  /*border-radius: 16px;*/
}

.login-body .login-description {
  max-width: 360px;
  font-size: 12px;
  font-family: var(--main-font-family);
}

.card-body-column {
  max-width: 400px;
  min-width: 296px;
}

@media (max-width: 960px) {
  .card-body {
    padding: 0 32px 48px;
  }

  .validate-phone-number .alert-danger {
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 500px) {
  .logo-header {
    height: 26px;
    margin-right: 1rem !important;
  }

  .logo-header-mi-tienda {
    height: 29px;
    margin-left: 1rem !important;
  }

  .btn-close {
    width: 0.7em;
    height: 0.7em;
    background-size: 0.7em auto;
  }

  .responsive-title {
    font-size: 24px;
  }
}

input[type='email'],
input[type='email']:focus,
input[type='password'],
input[type='password']:focus,
input[type='text'],
input[type='text']:focus,
input[type='tel'],
input[type='tel']:focus {
  border-radius: 8px;
  height: 42px;
}

.input-error {
  border: #e80815 1px solid !important;
  background-color: #ffffff !important;
}

.input-success {
  border: #83af43 1px solid !important;
  background-color: #ffffff !important;
}

.input-container {
  margin-top: -10px !important;
  position: relative;
}

.input-container i {
  position: absolute;
  padding: 13px 10px;
  color: #888888;
  top: 32px;
  right: 5px;
  cursor: pointer;
}

.input-with-icon {
  position: relative;
}

.input-with-icon i {
  top: 50%;
  transform: translate(0, -50%);
}

.input-helper {
  float: right;
  color: #202022;
  text-decoration: none;
}

.input-sms {
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
}

.text-orange {
  color: #dc291e !important;
}

.title {
  text-align: center;
  width: 100%;
}

.title .row {
  width: 100%;
}

.title h3 {
  font-family: var(--main-font-family);
  font-weight: 800;
}

.title p {
  font-family: var(--main-font-family);
  font-weight: 500;
  max-width: 360px;
  margin: 20px auto 0;
  font-size: 12px;
}

.back-button-color {
  color: #94a3b8 !important;
}

.text-black {
  color: #333333 !important;
}

.password-p {
  margin-top: 5px;
  margin-bottom: 0;
}

.small-print {
  font-size: 70%;
}
.disclaimer {
  color: var(--disclaimer-text-color);
}

.disclaimer-underline {
  color: var(--disclaimer-text-color);
}

.alert-error {
  background: #ffffff;
  color: #000000;
  border: #0b76e0 2px solid !important;
  line-height: 1.2;
}

.message-container {
  position: relative;
}
.alert-exclamation {
  position: absolute;
  top: -8px;
  left: 5px;
  color: #0b76e0;
}
.alert-check {
  position: absolute;
  top: -8px;
  left: 5px;
  color: #25d09f;
}
.alert-close {
  position: absolute;
  top: -8px;
  right: 5px;
}
.text-alert {
  font-size: 85%;
}
.icon-alert {
  position: relative;
  top: -8px;
}

/***************/
/*** BUTTONS ***/
.btn-link {
  font-size: 14px;
}

.btn-link-secondary {
  color: #4b8deb;
  text-decoration: none;
}

.btn-login {
  color: #ffffff;
  background-color: #009fce;
  border-radius: 8px;
  height: 48px;
}

.btn-login.mi-tienda {
  background-color: #009f5d;
}

.btn-login:hover,
.btn-login:focus,
.btn-login:active,
.btn-login.active,
.open .dropdown-toggle.btn-login {
  color: #ffffff;
  background-color: #009fce;
}

.btn-login.mi-tienda:hover,
.btn-login.mi-tienda:focus,
.btn-login.mi-tienda:active,
.btn-login.mi-tienda.active,
.open .dropdown-toggle.btn-login.mi-tienda {
  color: #ffffff;
  background-color: #009f5d;
}

.btn-login:active,
.btn-login.active,
.open .dropdown-toggle.btn-login {
  background-image: none;
}

.btn-login.disabled,
.btn-login[disabled],
fieldset[disabled] .btn-login,
.btn-login.disabled:hover,
.btn-login[disabled]:hover,
fieldset[disabled] .btn-login:hover,
.btn-login.disabled:focus,
.btn-login[disabled]:focus,
fieldset[disabled] .btn-login:focus,
.btn-login.disabled:active,
.btn-login[disabled]:active,
fieldset[disabled] .btn-login:active,
.btn-login.disabled.active,
.btn-login[disabled].active,
fieldset[disabled] .btn-login.active {
  background-color: #414142;
}

.btn-login.mi-tienda.disabled,
.btn-login.mi-tienda[disabled],
fieldset[disabled] .btn-login.mi-tienda,
.btn-login.mi-tienda.disabled:hover,
.btn-login.mi-tienda[disabled]:hover,
fieldset[disabled] .btn-login.mi-tienda:hover,
.btn-login.mi-tienda.disabled:focus,
.btn-login.mi-tienda[disabled]:focus,
fieldset[disabled] .btn-login.mi-tienda:focus,
.btn-login.mi-tienda.disabled:active,
.btn-login.mi-tienda[disabled]:active,
fieldset[disabled] .btn-login.mi-tienda:active,
.btn-login.mi-tienda.disabled.active,
.btn-login.mi-tienda[disabled].active,
fieldset[disabled] .btn-login.mi-tienda.active {
  background-color: #414142;
}

.btn-login .badge {
  color: #ffffff;
  background-color: #009fce;
}

.btn-login .badge {
  color: #ffffff;
  background-color: #009f5d;
}

.btn-social {
  color: #64748b;
  border-color: #cbd5e1;
  border-radius: 8px;
  height: 48px;
  align-items: center;
  padding: 12px;
}

.btn-social-content {
  font-size: 16px;
  display: flex;
}

.fa-brands {
  height: max-content;
  font-size: 25px;
}

.fa-apple {
  font-size: 24px;
  padding-bottom: 3px;
}

.button-logo {
  height: 24px;
}

.btn-social:hover,
.btn-social:focus,
.btn-social:active,
.btn-social.active,
.open .dropdown-toggle.btn-social {
  color: #009fce;
  border-color: #009fce;
}

.btn-social:active,
.btn-social.active,
.open .dropdown-toggle.btn-social {
  background-image: none;
}

.btn-social.disabled,
.btn-social[disabled],
fieldset[disabled] .btn-social,
.btn-social.disabled:hover,
.btn-social[disabled]:hover,
fieldset[disabled] .btn-social:hover,
.btn-social.disabled:focus,
.btn-social[disabled]:focus,
fieldset[disabled] .btn-social:focus,
.btn-social.disabled:active,
.btn-social[disabled]:active,
fieldset[disabled] .btn-social:active,
.btn-social.disabled.active,
.btn-social[disabled].active,
fieldset[disabled] .btn-social.active {
  background-color: #414142;
  border-color: #414142;
}

.btn-social .badge {
  color: #ededed;
  background-color: #4a4a4a;
}

.btn-fast-access {
  color: #4a4a4a;
  background-color: #ffffff;
  border-color: #cccccc;
}

.btn-fast-access:hover,
.btn-fast-access:focus,
.btn-fast-access:active,
.btn-fast-access.active,
.open .dropdown-toggle.btn-fast-access {
  color: #ffffff;
  background-color: #333333;
  border-color: #ededed;
}

.btn-fast-access:active,
.btn-fast-access.active,
.open .dropdown-toggle.btn-fast-access {
  background-image: none;
}

.btn-fast-access.disabled,
.btn-fast-access[disabled],
fieldset[disabled] .btn-fast-access,
.btn-fast-access.disabled:hover,
.btn-fast-access[disabled]:hover,
fieldset[disabled] .btn-fast-access:hover,
.btn-fast-access.disabled:focus,
.btn-fast-access[disabled]:focus,
fieldset[disabled] .btn-fast-access:focus,
.btn-fast-access.disabled:active,
.btn-fast-access[disabled]:active,
fieldset[disabled] .btn-fast-access:active,
.btn-fast-access.disabled.active,
.btn-fast-access[disabled].active,
fieldset[disabled] .btn-fast-access.active {
  background-color: #414142;
  border-color: #414142;
}

.btn-fast-access .badge {
  color: #ededed;
  background-color: #4a4a4a;
}

button.signup,
button.signup:visited,
button.signup:hover,
button.signup:active {
  color: #ffffff;
  height: 52px;
  background-color: #dc291e;
  border-radius: 8px;
}

button.signup.mi-tienda,
button.signup.mi-tienda:visited,
button.signup.mi-tienda:hover,
button.signup.mi-tienda:active {
  color: black;
  background-color: #f1bc00;
}

a.back,
a.back:visited,
a.back:hover,
a.back:active {
  /* color: #333333; */
  text-decoration: none;
}

.divider {
  border-top: 2px solid #cbd5e1;
}

.complete-divider {
  margin: 31px;
  width: 50%;
}

.vertical-divider {
  border-left: 1px solid rgba(102, 82, 0, 0.15);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  padding: 48px 20px 24px;
  border-radius: 5px;
  width: 500px;
  max-width: 80%;
  position: relative;
  text-align: center;
  gap: 1rem;
}

.modal-close-button {
  position: absolute;
  right: 10px;
  top: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
}

.bottom-whatsapp-alert {
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  position: absolute;
}

.whatsapp-alert-message {
  padding: 9px 0 9px 9px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.main-loader {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}